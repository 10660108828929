<template>
  <IAmOverlay
    :loading="isLoading"
    :spinner-variant="'info'"
  >
    <b-card
      v-if="!isMobileView"
      class="border-primary"
      style="z-index: 99"
      body-class="py-1 d-flex flex-wrap flex-lg-nowrap align-items-center justify-content-between"
    >
      <template v-if="!isLoading">
        <div class="d-flex-center">
          <b-dropdown
            id="dropdown-type"
            variant="flat-dark"
            class="justify-content-start border-warning"
            boundary="window"
          >
            <!-- toggle-class="py-75 px-1" -->
            <template #button-content>
              <div class="button-dropdown-class d-inline-flex align-items-center">
                <IAmIcon
                  icon="trainIcon"
                  size="22"
                />
                <span class="fw-700 ml-25 font-medium-1">
                  {{ dataSearch.tripType === 'OW' ? $t('flight.OW') : $t('flight.RT') }}
                </span>
              </div>
            </template>

            <b-dropdown-item @click="handleSelectTripType('OW')">
              <div class="d-flex-center">
                <!-- <b-img
                  class="airlines-logo px-0 mr-25"
                  blank-color="#ccc"
                  style="height: 18px;"
                  :src="require(`@icons/airplane.svg`)"
                /> -->
                {{ $t('flight.OW') }}
              </div>
            </b-dropdown-item>

            <b-dropdown-item @click="handleSelectTripType('RT')">
              <div class="d-flex-center">
                <!-- <b-img
                  class="airlines-logo px-0 mr-25"
                  blank-color="#ccc"
                  style="height: 18px;"
                  :src="require(`@icons/airplane.svg`)"
                /> -->
                {{ $t('flight.RT') }}
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div class="d-inline-flex mx-1">
          <SearchStopPoint :data-prop.sync="dataSearch.departure" />

          <b-button
            v-ripple.100
            variant="white"
            class="btn-icon rounded-circle py-50 px-1"
            @click="swapFromToHandle"
          >
            <!-- <IAmIcon
              icon="swap"
              class="d-flex-center text-white"
              size="20px"
            /> -->
            <img
              src="@icons/swap-horizontal.svg"
              alt="Swap"
            >
          </b-button>

          <SearchStopPoint
            :data-prop.sync="dataSearch.arrival"
            right
          />
        </div>

        <div class="d-flex">
          <SearchDatePicker
            :date.sync="dataSearch.departDate"
            :config="{
              minDate: 'today',
            }"
            class="w-100 fw-800"
            :icon="'@icons/airplane-up.svg'"
            @input="handleDepartDate"
          >
            <template #prefix>
              <IAmIcon
                icon="trainGo"
                size="22"
              />
              <span class="mx-50 text-nowrap font-medium-2">Ngày đi</span>
            </template>
          </SearchDatePicker>
        </div>

        <div
          v-if="dataSearch.tripType === 'RT'"
          class="d-flex"
        >
          <SearchDatePicker
            :date.sync="dataSearch.returnDate"
            :config="{
              minDate: 'today',
            }"
            class="w-100 fw-800"
            :icon="'@icons/airplane-up.svg'"
            @input="handleDepartDate"
          >
            <template #prefix>
              <IAmIcon
                icon="trainBack"
                size="22"
              />
              <span class="mx-50 text-nowrap font-medium-2">Ngày về</span>
            </template>
          </SearchDatePicker>
        </div>

        <div>
          <b-button
            v-ripple.400
            class="btn-gradient p-75 text-truncate rounded"
            :disabled="loading"
            @click="handleSearchButton"
          >
            <div class="d-flex align-items-center">
              <img
                src="@icons/search.svg"
                alt="Search"
                class="ico-bold"
              >
            </div>
          </b-button>
        </div>
      </template>
    </b-card>
  </IAmOverlay>
</template>

<script>
import {
  BCard, BButton, BRow, BCol, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import {
  computed, ref, toRefs, watchEffect,
} from '@vue/composition-api'
import isEmpty from 'lodash/isEmpty'
import cloneDeep from 'lodash/cloneDeep'
import moment from 'moment'

import IAmOverlay from '@/components/IAmOverlay.vue'

import { convertISODateTime } from '@core/utils/filter'

import useTrainHandle from '@train/useTrainHandle'
import useToast from '@useToast'

export default {
  components: {
    BCard,
    BButton,
    BRow,
    BCol,
    IAmOverlay,
    BDropdown,
    BDropdownItem,
    SearchStopPoint: () => import('./components/SearchStopPoint.vue'),
    SearchDatePicker: () => import('./components/SearchDatePicker.vue'),

  },
  props: {
    payload: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const { toastWarning } = useToast()

    const {
      loading,
      searchTrain,
      getSearchPayloadArray,
      handleSetTabIndex,
    } = useTrainHandle()

    const { payload } = toRefs(props)
    const dataSearch = ref(cloneDeep(payload.value))

    const isLoading = computed(() => isEmpty(payload.value))

    function swapFromToHandle() {
      const temp = dataSearch.value.departure
      dataSearch.value.departure = dataSearch.value.arrival
      dataSearch.value.arrival = temp
    }

    function handleSelectTripType(val) {
      dataSearch.value.tripType = val
    }

    function handleDepartDate(date) {
      const departDate = new Date(date)
      if (departDate > new Date(dataSearch.value.returnDate)) {
        dataSearch.value.returnDate = moment.utc(departDate).add(3, 'days').format('YYYY-MM-DD')
      }
    }

    // function modifyDataSearch() {
    //   const { from, to, ...rest } = dataSearch.value
    //   const payload = {
    //     ...rest,
    //     departure: from.code,
    //     arrival: to.code,
    //   }
    //   return JSON.stringify(payload)
    // }

    async function handleSearchButton() {
      if (['RT'].includes(dataSearch.value.tripType) && !dataSearch.value.returnDate) {
        toastWarning({
          title: 'Vui lòng chọn ngày về !',
        })
        return
      }
      localStorage.setItem('trainSearch', JSON.stringify(dataSearch.value))

      await searchTrain(dataSearch.value)
    }

    return {
      loading,
      isLoading,
      dataSearch,
      getSearchPayloadArray,
      handleSetTabIndex,
      convertISODateTime,
      handleSelectTripType,
      swapFromToHandle,
      handleDepartDate,
      handleSearchButton,
    }
  },
}
</script>

<style lang="scss" scoped>
#dropdown-type ::v-deep {
  button.dropdown-toggle {
    &::after {
      top: -2px;
    }
  }
  .dropdown-menu {
    z-index: 99;
  }
}
</style>
